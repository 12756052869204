import {WINENAME} from '../types/type'





const intialState  = null



 const wineNameReducers = (state = intialState, action)=>{
    switch(action.type){
      case WINENAME: return state = action.payload

        
        default:
        return state;
    }
  };

  export default wineNameReducers

