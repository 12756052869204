import {combineReducers} from 'redux'
import toggleReducers from './toggleReducers'
import toastReducers from './toastReducer'
import toastErrReducers from './toastErrReducers'
import wineNameReducers from './wineNameReducers'





  const rootReducer = combineReducers({
    toggleReducers,
    toastReducers,
    toastErrReducers,
    wineNameReducers
})

export default rootReducer